<template>
  <div>
    <profileParts />
    <navbar :key="updateKey" />
    <transition name="flixFadeIn">
      <div :key='$route.params.id + updateKey'>
        <alert v-if="$route.params.id === 'profile'" />
        <profile v-if="$route.params.id === 'profile'" :saveData="function(data){ saveData(data) }"/>
        <imprint v-if="$route.params.id === 'imprint'" :saveData="function(data){ saveData(data) }"/>
        <privacy v-if="$route.params.id === 'privacy'" :saveData="function(data){ saveData(data) }"/>
        <terms v-if="$route.params.id === 'terms'" :saveData="function(data){ saveData(data) }"/>
        <transition name="flixFadeIn2">
          <save :key="save" v-if="save"/>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    profileParts () { return import('@/components/profile/parts') },
    profile () { return import('@/components/profile/profile') },
    navbar () { return import('@/components/profile/navbar') },
    imprint () { return import('@/components/profile/imprint') },
    terms () { return import('@/components/profile/terms') },
    alert () { return import('@/components/profile/alert') },
    privacy () { return import('@/components/profile/privacy') },
    save () { return import('@/components/profile/save') }
  },
  props: {},
  data () {
    return {
      save: false,
      updateKey: new Date().getTime()
    }
  },
  methods: {
    saveData (user) {
      var send = {}
      send.user = this.$store.getters.user.md5_id
      delete user.privacy
      delete user.imprint
      delete user.terms
      send.data = JSON.parse(JSON.stringify(user))

      window.localStorage.setItem('flix_login', btoa(JSON.stringify(user)))
      this.$store.commit('setUser', JSON.parse(JSON.stringify(user)))
      delete send.data.ID
      delete send.data.md5_id
      delete send.data.username
      delete send.data.password
      delete send.data.registration
      delete send.data.status
      delete send.data.accept_terms
      delete send.data.language
      delete send.data.role
      delete send.data.fb_id
      delete send.data.max_calendar
      delete send.data.sms
      send.data.vat = JSON.stringify(send.data.vat)

      this.$flix_post({
        url: 'user/update',
        data: send,
        callback: function (ret) {
          // window.console.log(ret)
        }
      })

      this.save = true
      this.updateKey = new Date().getTime()

      setTimeout(function () {
        this.save = false
      }.bind(this), 3000)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
